import React from 'react';

const Projects = () => {
  return (
    <section id="projects" className="bg-gray-200 py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Nossos Projetos</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-white p-4 rounded-lg shadow-lg text-center">
            <img src="/images/hero.png" alt="Projeto Comercial" className="w-full h-96 object-cover rounded-t-lg mb-4" />
            <h3 className="text-xl font-bold mb-4">Reconstrução do Rio Grande do Sul</h3>
            <p>Estamos trabalhando em projetos de reconstrução do Estado do Rio Grande do Sul.</p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-lg text-center">
            <img src="/images/construcao.png" alt="Projeto Residencial" className="w-full h-96 object-cover rounded-t-lg mb-4" />
            <h3 className="text-xl font-bold mb-4">Construção com Tecnologia Avançada</h3>
            <p>Utilizando tecnologia avançada de construção in-loco de parede de concreto com formas, esta casa foi projetada para otimizar custo e espaço. A técnica permite uma construção rápida e eficiente, garantindo um lar econômico e moderno, sem abrir mão do conforto e da funcionalidade.</p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-lg text-center">
            <img src="/images/casa pre moldada.jpg" alt="Projeto Industrial" className="w-full h-96 object-cover rounded-t-lg mb-4" />
            <h3 className="text-xl font-bold mb-4">Projeto de Casas Populares</h3>
            <p>Estamos trabalhando para reconstruir casas no Rio Grande do Sul.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Projects;
