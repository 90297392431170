import React from 'react';

const About = () => {
  return (
    <section id="about" className="py-20 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Sobre Nós</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-xl font-semibold mb-4">Política da Qualidade</h3>
            <p className="text-gray-700">
              A MRE Construções Ltda., busca o crescimento da empresa e aprimoramento contínuo dos processos construtivos, atendimento aos requisitos aplicáveis, visando a lucratividade, a qualidade das obras, a sustentabilidade dos canteiros e satisfação máxima de nossos clientes e colaboradores.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-xl font-semibold mb-4">Missão</h3>
            <p className="text-gray-700">
              Contribuir para o desenvolvimento urbano e sustentável, oferecendo soluções em construção civil de alta qualidade, com foco na satisfação do cliente, inovação e responsabilidade socioambiental.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-xl font-semibold mb-4">Visão</h3>
            <ul className="text-gray-700 text-left">
              <li className="mb-2">• Ser referência no mercado da construção civil no Rio Grande do Sul.</li>
              <li className="mb-2">• Reconhecida pela excelência nos serviços prestados.</li>
              <li className="mb-2">• Compromisso com prazos e adoção de práticas sustentáveis.</li>
            </ul>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-xl font-semibold mb-4">Valores</h3>
            <ul className="text-gray-700 text-left">
              <li className="mb-2">• Ética e Transparência: Agir com integridade em todas as relações comerciais e pessoais.</li>
              <li className="mb-2">• Sustentabilidade: Promover práticas que minimizem impactos ambientais e valorizem os recursos naturais.</li>
              <li className="mb-2">• Qualidade: Garantir padrões elevados em todos os projetos.</li>
              <li className="mb-2">• Compromisso: Respeitar prazos e superar as expectativas dos clientes.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
